.notifications {
  position: fixed;
  z-index: 400;
  top: calc(var(--layout-height-header) + 1rem);
  right: var(--layout-spacing-unit);
  width: 90vw;
  max-width: 300px;
  max-height: 100%;
  overflow-y: auto;
  padding-right: calc(3 * var(--layout-spacing-unit));
}
.notifications__close-all {
  display: flex;
  justify-content: flex-end;
  margin-bottom: calc(4 * var(--layout-spacing-unit));
}
/*# sourceMappingURL=src/components/notifications/Notifications.css.map */