.flyout-wrapper .notification__closing-svg {
  display: none;
}
.notification {
  border-radius: var(--border-radius-notification);
  margin-bottom: calc(2 * var(--layout-spacing-unit));
  padding: var(--layout-spacing-unit);
  padding-bottom: var(--layout-spacing-unit);
  padding-left: calc(3 * var(--layout-spacing-unit));
}
.notification.notification__dismissing .notification__closing-svg circle {
  stroke-dashoffset: 283;
  transition: stroke-dashoffset linear var(--closing-duration);
}
.notification__closing-button:hover .notification__closing-svg,
.notification__closing-button:focus .notification__closing-svg {
  display: none;
}
.notification__closing-svg {
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  pointer-events: none;
}
.notification__closing-svg circle {
  display: block;
  fill: transparent;
  stroke: var(--color-focus);
  stroke-linecap: round;
  stroke-dasharray: 283;
  stroke-dashoffset: 0;
  stroke-width: 0.2rem;
  transform-origin: 50% 50%;
  transform: scale(1, -1) rotate(90deg);
}
.notification .icon-button {
  border: 0.1rem solid transparent;
}
.notification .icon-button:hover,
.notification .icon-button:focus,
.notification .icon-button:active {
  background-color: transparent;
  border-color: var(--color-focus);
}
.notification--default {
  background-color: var(--bgc-popup);
}
.notification--success {
  background-color: var(--bgc-success);
}
.notification--warning {
  background-color: var(--bgc-warning);
}
.notification--error {
  background-color: var(--bgc-error);
}
.notification__header {
  display: flex;
  align-items: center;
}
.notification__title {
  flex: 1 1 auto;
}
.notification__description {
  padding: 0 !important;
  overflow: auto;
  margin-top: var(--layout-spacing-unit);
  padding-right: calc(2 * var(--layout-spacing-unit));
}
.notification__description>a {
  color: var(--color-white) !important;
  transition: color var(--portal-transition-duration);
  text-decoration: underline;
}
/*# sourceMappingURL=src/components/notifications/Notification.css.map */