.header-tab {
  outline: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  background-color: transparent;
  transition: background-color var(--portal-transition-duration);
  flex-basis: auto;
  flex-grow: 1;
  max-width: 15rem;
  border: 0.2rem solid rgba(0,0,0,0);
}
.header-tab__logo-wrapper {
  background-color: var(--bgc-apptile-default);
  border-radius: var(--border-radius-apptile);
  height: calc(var(--portal-header-height) * var(--portal-header-icon-scale));
  width: calc(var(--portal-header-height) * var(--portal-header-icon-scale));
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 var(--layout-spacing-unit-small);
}
.header-tab__logo {
  width: 80%;
  max-height: 80%;
  vertical-align: middle;
  border: 0;
}
.header-tab__title {
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 2.5rem;
}
.header-tab__close-button {
  position: relative;
  z-index: 10;
}
.header-tab__focus-wrapper {
  display: flex;
  align-items: center;
  min-width: 40px;
  width: 100%;
}
.header-tab:focus {
  border-color: var(--color-focus);
}
.header-tab--active {
  background-color: var(--portal-tab-background);
}
/*# sourceMappingURL=src/components/navigation/HeaderTab.css.map */