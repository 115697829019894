.portal-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 300;
  background-color: var(--bgc-content-header);
  color: var(--font-color-contrast-high);
  height: var(--portal-header-height);
  display: flex;
  padding: 0 calc(2 * var(--layout-spacing-unit));
}
.portal-header__portal-name {
  font-size: var(--font-size-2);
  white-space: nowrap;
}
.portal-header__left {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0px 10px;
  border: 0.2rem solid rgba(0,0,0,0);
}
.portal-header__left:focus {
  border: 0.2rem solid var(--color-focus);
  outline: 0;
}
.portal-header__left-image {
  height: 100%;
  width: calc(var(--portal-header-height) * var(--portal-header-icon-scale));
}
.portal-header__left-image + h1 {
  padding-left: var(--layout-spacing-unit);
}
.portal-header__tabs {
  display: flex;
  flex: 1 1 auto;
  margin-left: calc(5 * var(--layout-spacing-unit));
  width: 100%;
  overflow: hidden;
}
.portal-header__right {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.portal-header__edit-mode-label {
  white-space: nowrap;
}
.portal-header__portal-home-icon {
  display: none;
}
@media only screen and (max-width: 748px) {
  .portal-header__portal-home-icon {
    display: flex;
    align-content: center;
  }
  .portal-header__portal-home-icon svg {
    width: calc(3 * var(--layout-spacing-unit));
    height: calc(3 * var(--layout-spacing-unit));
  }
}
#header-button-copy {
  display: none;
}
.portal-header__tabs-overflow .portal-header__tabs {
  visibility: hidden;
}
.portal-header__tabs-overflow #header-button-copy {
  display: flex;
}
/*# sourceMappingURL=src/components/PortalHeader.css.map */