.header-button {
  --font-size-button-icon: var(--font-size-2);
  margin: 0 var(--layout-spacing-unit-small);
  --bgc: transparent;
  --bgc-state: transparent;
  box-shadow: none;
  border-radius: var(--button-icon-border-radius);
  height: calc(4.5 * var(--layout-spacing-unit));
  width: calc(4.5 * var(--layout-spacing-unit));
}
.header-button--is-active {
  z-index: 1000;
}
.header-button--is-active svg {
  color: var(--color-accent);
}
.header-button__inner {
  border: none;
  border-radius: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--button-bgc-transition);
  background-color: var(--bgc-state);
  transition: opacity var(--portal-transition-duration);
  font-size: var(--button-font-size);
  width: inherit;
  height: inherit;
}
.header-button__button {
  position: relative;
  font-size: var(--font-size-3);
  background: none;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 0.2rem solid rgba(0,0,0,0);
  padding: var(--layout-spacing-unit);
  width: inherit;
  height: inherit;
  border-radius: var(--border-radius-circles);
}
.header-button__button:hover,
.header-button__button:focus {
  border-radius: 100%;
}
.header-button__button:focus {
  border: 0.2rem solid var(--color-focus);
  outline: none;
}
.header-button__button:hover {
  cursor: pointer;
}
.header-button__detail {
  position: absolute;
  color: var(--bgc-content-header);
  background-color: var(--bgc-header-number-circle);
  font-size: var(--font-size-5);
  width: 1.6em;
  height: 1.6em;
  left: 2em;
  top: -0.5em;
  border-radius: var(--border-radius-circles);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
/*# sourceMappingURL=src/components/navigation/HeaderButton.css.map */