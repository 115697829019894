
.modal-wrapper
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -999

    &--isVisible
      z-index: $zindex-2
      background-color: var(--bgc-underlay);
      display: flex
      align-items: center
      justify-content: center

      &> *
        position: relative
        z-index: 1

    &--isVisibleFullscreen
      z-index: $zindex-4
