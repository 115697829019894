
.portal-sidenavigation
  height: calc(100vh - (var(--portal-header-height) + 0.5rem))
  display: flex
  flex-direction: column

  @media $mqSmartphone
    overflow-y: auto

  &__link
    padding: 1em 0 1em 20px
    cursor: pointer
    height: auto
    width: 100%
    justify-content: left
    font-size: var(--font-size-4)
    color: var(--font-color-contrast-high)
    font-weight: 600
    text-transform: uppercase
    background-color: rgba(0,0,0,0)
    border: 0.2rem solid rgba(0,0,0,0)
    &:focus
      border: 0.2rem solid var(--color-focus);
      outline: 0
  &__user-row
    padding-left: var(--layout-spacing-unit)
    display: flex
    height: 6rem
    font-weight: var(--font-weight-bold)

    svg
      fill: currentColor
      height: 4rem
      width: @height
      margin: 1rem
      border-radius: var(--border-radius-circles)
    &> div
      margin: auto 0
      padding-left: var(--layout-spacing-unit)
  &--username
    padding-left: 3px

  &__logout-link
    cursor: pointer
    background-color: rgba(0,0,0,0)
    color: var(--font-color-contrast-high)
    font-size: var(--font-size-4)
    border: 0.2rem solid rgba(0,0,0,0);

    &:focus
      border: 0.2rem solid var(--color-focus);
      outline: 0

    span
      text-decoration: underline

  &__login-header
    border-bottom: 4px solid var(--bgc-content-body)

  &__menu
    margin: 0
    margin-bottom: auto
    padding-left: 0

  &__menu-item
    margin-left: 0

    &--show
      display: block

    &--hide
      display: none

  &__menu-subItem
    margin-left: 0
    &--parent
      text-transform: uppercase;
      padding-left: 4rem;

  &__edit-mode
    border: none
    border-radius: unset
    border-top: 0.2rem solid var(--bgc-content-body)

    span
      margin: 0.2rem

    &:focus span
      margin: 0

  &__fade-left-right,
  &__fade-right-left
    animation-duration: .4s;

  &__fade-right-left
    animation-name: fadeOutRight

  &__fade-left-right
    animation-name: fadeInLeft

// keyframes
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(20rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeOutRight {
  0% {
    opacity: 0;
    transform: translateX(20rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
