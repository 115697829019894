
.edit-mode-side-navigation
  &__form
    height: auto
    overflow: auto
    padding: calc(2 * var(--layout-spacing-unit))

    input
      width: 18rem
      &[type=checkbox]
        margin-left: 0
  &__save-button
    margin-top: calc(2 * var(--layout-spacing-unit))
