.portal-tile {
  position: relative;
  outline: 0;
  width: var(--app-tile-side-length);
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: var(--font-color-contrast-high);
  text-decoration: none;
}
.portal-tile:hover {
  color: var(--font-color-contrast-high);
  text-decoration: none;
}
.portal-tile__root-element {
  display: flex;
  justify-content: center;
  position: relative;
}
.portal-tile__box {
  border-radius: var(--border-radius-apptile);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--box-shadow);
  background-color: var(--bgc-apptile-default);
  width: var(--app-tile-side-length);
  height: var(--app-tile-side-length);
  margin-bottom: calc(2 * var(--layout-spacing-unit));
  border: 0.2rem solid transparent;
  box-sizing: border-box;
}
.portal-tile:focus .portal-tile__box {
  border-color: var(--color-focus);
}
.portal-tile__box--draggable {
  position: relative;
}
.portal-tile__box--draggable:after {
  content: ' ';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
}
.portal-tile__img {
  width: 80%;
  max-height: 80%;
}
.portal-tile__name {
  text-align: center;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-shadow: 0 0.1rem 0.1rem rgba(0,0,0,0.3);
}
.portal-tile__edit-button,
.portal-tile__info-button {
  position: absolute;
  top: -0.75em;
  right: -0.75em;
  z-index: 100;
}
.portal-tile__edit-button--in-modal,
.portal-tile__info-button--in-modal {
  position: relative;
}
.portal-tile__info-button {
  font-size: var(--font-size-2);
}
.portal-tile__info-button svg {
  width: calc(1.5 * var(--button-icon-size));
  height: calc(1.5 * var(--button-icon-size));
}
.portal-tile__modal {
  width: 650px;
}
/*# sourceMappingURL=src/components/PortalTile.css.map */