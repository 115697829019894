.link-widget {
  display: flex;
  align-items: center;
}
.link-widget__select {
  max-width: 5rem;
}
.link-widget__select select {
  width: 5rem;
}
.link-widget__input {
  width: 100%;
  margin-left: 0.5rem;
  margin-right: 2rem;
}
.link-widget__input input {
  width: 100%;
}
.link-widget__remove {
  width: 3rem;
  margin-bottom: var(--layout-spacing-unit);
}
/*# sourceMappingURL=src/components/widgets/LinkWidget.css.map */