
.portal-header
  position: fixed
  top: 0
  left: 0
  right: 0
  z-index: $zindex-3
  background-color: var(--bgc-content-header)
  color: var(--font-color-contrast-high)
  height: var(--portal-header-height)
  display: flex
  padding: 0 calc(2 * var(--layout-spacing-unit))

  &__portal-name
    font-size: var(--font-size-2);
    white-space: nowrap

  &__left
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0px 10px
    border: 0.2rem solid rgba(0,0,0,0)

    &:focus
      border: 0.2rem solid var(--color-focus)
      outline: 0

    &-image
      height: 100%
      width: calc(var(--portal-header-height) * var(--portal-header-icon-scale))

      + h1
        padding-left: var(--layout-spacing-unit)

  &__tabs
    display: flex;
    flex: 1 1 auto;
    margin-left: calc(5 * var(--layout-spacing-unit));
    width: 100%;
    overflow: hidden

  &__right
    margin-left: auto
    display: flex;
    align-items: center;

  &__edit-mode-label
    white-space: nowrap

  &__portal-home-icon
    display: none
    @media $mqSmartphone
      display: flex
      align-content: center

      svg
        width: calc(3* var(--layout-spacing-unit))
        height: @width

#header-button-copy
    display: none

.portal-header__tabs-overflow
  .portal-header
    &__tabs
      visibility: hidden
  #header-button-copy
      display: flex
