
.tile-add
  margin: 0
  min-width: var(--app-tile-side-length)
  width: var(--app-tile-side-length)
  height: var(--app-tile-side-length)
  border-radius: var(--border-radius-apptile)
  border: 0.2rem solid var(--button-bgc)
  background-color: transparent
  cursor: pointer
  box-sizing: border-box

  &:focus
    border-color: var(--color-focus)

  &:focus, &:hover
    background-color: transparent

  svg
    width: 100%
    height: 100%
    stroke: var(--button-bgc)
