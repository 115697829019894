.edit-mode-side-navigation__form {
  height: auto;
  overflow: auto;
  padding: calc(2 * var(--layout-spacing-unit));
}
.edit-mode-side-navigation__form input {
  width: 18rem;
}
.edit-mode-side-navigation__form input[type=checkbox] {
  margin-left: 0;
}
.edit-mode-side-navigation__save-button {
  margin-top: calc(2 * var(--layout-spacing-unit));
}
/*# sourceMappingURL=src/components/navigation/EditModeSideNavigation.css.map */