.modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -999;
}
.modal-wrapper--isVisible {
  z-index: 200;
  background-color: var(--bgc-underlay);
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-wrapper--isVisible> * {
  position: relative;
  z-index: 1;
}
.modal-wrapper--isVisibleFullscreen {
  z-index: 400;
}
/*# sourceMappingURL=src/components/globals/ModalWrapper.css.map */