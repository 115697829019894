
.dialog
  padding: calc(2 * var(--layout-spacing-unit)) calc(4 * var(--layout-spacing-unit))
  background: var(--bgc-content-container)
  border-radius: var(--border-radius-container)
  max-width: calc(50 * var(--layout-spacing-unit))
  box-shadow: var(--box-shadow)

  form
    width: calc(var(--inputfield-width) + 3rem)

  main
    max-height: 26rem
    overflow: auto
    padding-right: var(--layout-spacing-unit)

    > label:first-child
      margin-top: 0

  footer:not(.image-upload__footer)
    margin-top: calc(2 * var(--layout-spacing-unit))
    padding-top: calc(2 * var(--layout-spacing-unit))
    border-top: thin solid var(--bgc-tab-separator)
    /* padding: var(--layout-spacing-unit-small) calc(2 * var(--layout-spacing-unit))*/
    display: flex
    justify-content: space-between
    flex-wrap: wrap

    button:last-of-type
      margin-left: auto

  &__header
    display: flex
    align-items: center

    button
      margin-left: auto
