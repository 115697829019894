
.portal-tooltip
  position: fixed
  bottom: calc(2 * var(--layout-spacing-unit))
  right: calc(2 * var(--layout-spacing-unit))
  background-color: var(--bgc-content-container)
  border-radius: var(--border-radius-container)
  min-width: calc(20 * 1rem)
  max-width: calc(20 * 1rem)
  padding: calc(2 * var(--layout-spacing-unit))
  box-shadow: var(--box-shadow)
  pointer-events: none
  z-index: $zindex-3
  display: block;

  @media $mqSmartphone
    bottom: unset;
    top: calc(3 * var(--layout-spacing-unit))
    min-width: 4rem
    max-width: calc(20 * 1rem)
    width: 90%
    left:0
    right:0
    margin-left:auto
    margin-right:auto

  &__header
    display: flex
    align-items: center
    margin-bottom: 1rem

  &__thumbnail
    border-radius: var(--border-radius-apptile)
    display: flex
    align-items: center
    justify-content: center
    box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.16)
    background-color: var(--bgc-apptile-default)

    .portal-tooltip__header &
      width: calc(3 * 1rem)
      height: calc(3 * 1rem)
      margin-right: calc(3 * calc(1rem / 2))

  &__logo
    width: 80%
    max-height: 80%
    vertical-align: middle
    border: 0
