.portal-folder {
  position: relative;
  width: var(--app-tile-side-length);
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.portal-folder__name {
  text-align: center;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-shadow: 0 0.1rem 0.1rem rgba(0,0,0,0.3);
}
.portal-folder__in-modal {
  cursor: default;
}
.portal-folder__in-modal button {
  text-transform: none;
}
.portal-folder__in-modal .portal-folder__name {
  font-size: var(--font-size-1);
  width: unset;
}
.portal-folder__in-modal > .portal-tile__box {
  width: calc(5 * var(--app-tile-side-length));
  height: calc(5 * var(--app-tile-side-length));
  max-width: 100vw;
  margin-bottom: 0;
  max-height: 80vh;
  border-radius: 4rem;
}
@media only screen and (max-width: 748px) {
  .portal-folder__in-modal > .portal-tile__box {
    max-width: 90vw;
    margin-bottom: 0;
    max-height: 90vw;
    border-radius: 2rem;
  }
}
@media only screen and (max-width: 748px) {
  .portal-folder__in-modal > .portal-tile__box--accessible-zoom {
    max-height: calc(100vh - var(--portal-header-height) - (10 * var(--layout-spacing-unit)));
    margin-top: calc(var(--portal-header-height) + var(--layout-spacing-unit));
  }
}
.portal-folder__in-modal > .portal-tile__box .portal-tile {
  width: var(--app-tile-side-length);
}
.portal-folder__in-modal > .portal-tile__box .portal-tile__box {
  width: var(--app-tile-side-length);
  height: var(--app-tile-side-length);
  margin-bottom: calc(2 * var(--layout-spacing-unit));
}
.portal-folder__in-modal .portal-folder__thumbnail {
  margin-bottom: calc(5 * var(--layout-spacing-unit));
}
.portal-folder__in-modal .portal-folder__thumbnails .portal-tile__name {
  display: block;
}
.portal-folder__thumbnails {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  padding: 0.3rem;
  box-sizing: border-box;
  overflow: hidden;
}
.portal-folder__thumbnails > div {
  display: flex;
  align-content: center;
  justify-content: center;
}
.portal-folder__thumbnails--in-modal {
  max-height: calc(100vh - var(--portal-header-height) - var(--portal-header-height) - var(--portal-header-height));
  overflow: auto;
  box-sizing: border-box;
  padding: var(--portal-folder-padding);
  padding-bottom: 0;
}
.portal-folder__thumbnails--in-modal > div {
  height: auto;
}
.portal-folder__thumbnails--in-modal .portal-folder__thumbnail--tile-add {
  align-items: start;
}
.portal-folder__thumbnails--in-modal .portal-folder__thumbnail:after {
  display: none;
}
.portal-folder__thumbnails--in-modal .portal-folder__thumbnail:nth-child(n+10) {
  display: block;
}
.portal-folder__thumbnails .portal-tile {
  width: calc(0.25 * var(--app-tile-side-length));
}
.portal-folder__thumbnails .portal-tile__box {
  width: calc(0.25 * var(--app-tile-side-length));
  height: calc(0.25 * var(--app-tile-side-length));
  padding: calc(var(--layout-spacing-unit));
  margin-bottom: 0;
}
.portal-folder__thumbnails .portal-tile__name {
  display: none;
}
.portal-folder__thumbnails .portal-tile__root-element {
  align-items: center;
}
.portal-folder__thumbnail {
  margin-bottom: 0;
  display: flex;
  align-content: center;
  justify-content: center;
  width: var(--portal-folder-tile-width);
  height: var(--portal-folder-tile-width);
}
@media only screen and (max-width: 748px) {
  .portal-folder__thumbnail {
    height: 50%;
    width: var(--portal-folder-tile-width);
    max-width: 50%;
  }
}
.portal-folder__edit-button {
  position: absolute;
  top: -0.75em;
  right: -0.75em;
  z-index: 100;
}
.portal-folder .portal-tile__box {
  background-color: var(--bgc-content-container);
  padding: 0;
}
.portal-folder .portal-tile__box .portal-tile__box {
  background-color: var(--bgc-apptile-default);
}
.portal-folder__thumbnail:nth-child(n+10) {
  display: none;
}
.portal-folder__thumbnail--desktop {
  position: relative;
}
.portal-folder__thumbnail--desktop .portal-tile__box {
  box-shadow: none;
}
.portal-folder__thumbnail--desktop:after {
  content: '...';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  bottom: 0;
  right: 0;
  line-height: 300%;
  background-color: var(--bgc-content-container);
}
@media only screen and (max-width: 748px) {
  .portal-folder__thumbnail--desktop {
    display: none;
  }
}
.portal-folder__thumbnail--mobile {
  position: relative;
}
@media only screen and (max-width: 748px) {
  .portal-folder__thumbnail--mobile:after {
    content: '...';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    bottom: 0;
    right: 0;
    line-height: 300%;
    background-color: var(--bgc-content-container);
  }
}
:focus {
  border-color: var(--color-focus);
  outline: none;
}
/*# sourceMappingURL=src/components/PortalFolder.css.map */