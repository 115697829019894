
.portal-folder
  position: relative
  width: var(--app-tile-side-length)
  display: flex
  flex-direction: column
  align-items: center
  cursor: pointer

  &__name
    text-align: center
    width: 100%
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    text-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.3)

  &__in-modal
    cursor: default

    button
      text-transform: none

    .portal-folder__name
      font-size: var(--font-size-1)
      width: unset

    > .portal-tile

      &__box // Big FOLDER
        width: calc(5 * var(--app-tile-side-length))
        height: @width
        max-width: 100vw
        margin-bottom: 0
        max-height: 80vh
        border-radius: 4rem

        @media $mqSmartphone
          max-width: 90vw
          margin-bottom: 0
          max-height: 90vw
          border-radius: 2rem

        &--accessible-zoom
          @media $mqSmartphone
            max-height: calc(100vh -  var(--portal-header-height) - (10 * var(--layout-spacing-unit)));
            margin-top: calc(var(--portal-header-height) + var(--layout-spacing-unit));

        .portal-tile
          width: var(--app-tile-side-length)

          &__box
            width: var(--app-tile-side-length)
            height: @width
            margin-bottom: calc(2 * var(--layout-spacing-unit))
    .portal-folder__thumbnail
      margin-bottom: calc(5 * var(--layout-spacing-unit))

    .portal-folder__thumbnails .portal-tile__name
        display: block;

  &__thumbnails
    width: 100%
    height: 100%
    display: flex
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    padding: 0.3rem;
    box-sizing: border-box;
    overflow: hidden
    > div
        display: flex
        align-content: center
        justify-content: center

    &--in-modal
      max-height: calc(100vh - var(--portal-header-height) - var(--portal-header-height) - var(--portal-header-height));
      overflow: auto
      box-sizing: border-box;
      padding:  var(--portal-folder-padding)
      padding-bottom: 0

      > div
        height: auto
      .portal-folder__thumbnail--tile-add
        align-items: start
      .portal-folder__thumbnail:after {
        display: none;
      }
      .portal-folder__thumbnail:nth-child(n+10)
        display: block
    .portal-tile
      width: calc(0.25 * var(--app-tile-side-length))
      &__box
        width: calc(0.25 * var(--app-tile-side-length))
        height: @width
        padding:  calc(var(--layout-spacing-unit))
        margin-bottom: 0

      &__name
        display: none
      &__root-element
        align-items: center
      ^[0]__thumbnail
        margin-bottom: 0
        display: flex
        align-content: center
        justify-content: center
        width: var(--portal-folder-tile-width)
        height: var(--portal-folder-tile-width)

        @media $mqSmartphone
          height: 50%
          width: var(--portal-folder-tile-width)
          max-width: 50%

  &__edit-button
    position: absolute
    top: -0.75em
    right: -0.75em
    z-index: $zindex-1

  .portal-tile__box
    background-color: var(--bgc-content-container)
    padding: 0

    .portal-tile__box
      background-color: var(--bgc-apptile-default)

  &__thumbnail
    &:nth-child(n+10)
      display: none
    &--desktop
      position: relative

      .portal-tile__box
        box-shadow: none

      &:after
          content: '...'
          position: absolute
          width: 100%
          height: @width
          top: 0
          bottom:0
          bottom: 0;
          right: 0
          line-height: 300%
          background-color: var(--bgc-content-container)
        @media $mqSmartphone
          display: none

    &--mobile
      position: relative

      &:after
        @media $mqSmartphone
          content: '...'
          position: absolute
          width: 100%
          height: @width
          top: 0
          bottom:0
          bottom: 0;
          right: 0
          line-height: 300%
          background-color: var(--bgc-content-container)

&:focus
  border-color: var(--color-focus)
  outline: none;

