
.flyout-wrapper
  width: 22rem
  max-width: 22rem
  min-height: 100vh
  position: fixed
  top:  calc(var(--portal-header-height) + 0.5rem)
  right: 0
  z-index: 100
  background-color: var(--bgc-content-container)
  transform: translate3d(110%, 0, 0)
  transition: transform cubic-bezier(0, 0, 0.2, 1) 0.5s

  @media $mqSmartphone
     max-width: 90vw

  &--isVisible
    transform: translate3d(0, 0, 0) scale(1, 1)
    transition: transform cubic-bezier(0, 0, 0.2, 1) 0.5s
