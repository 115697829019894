.choose-tab {
  margin-top: calc(4 * var(--layout-spacing-unit));
  display: flex;
  align-items: center;
}
.choose-tab:first-of-type {
  margin-top: 0;
}
.choose-tab__button {
  display: flex;
  background-color: var(--button-bgc);
  align-items: center;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: var(--border-radius-container);
  padding: var(--layout-spacing-unit);
  width: 100%;
}
.choose-tab__button:focus,
.choose-tab__button:active,
.choose-tab__button:hover {
  border-color: var(--color-focus);
}
.choose-tab__button img {
  height: var(--button-size);
  margin-right: var(--layout-spacing-unit);
}
.choose-tab .icon-button {
  margin-left: var(--layout-spacing-unit);
}
/*# sourceMappingURL=src/components/ChooseTabs.css.map */