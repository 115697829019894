
.link-widget
  display: flex
  align-items: center

  &__select
    max-width: 5rem

    select
      width: 5rem

  &__input
    width: 100%
    margin-left: 0.5rem
    margin-right: 2rem

    input
      width: 100%

  &__remove
    width: 3rem
    margin-bottom: var(--layout-spacing-unit)
