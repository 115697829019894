.portal-search__input {
  width: 100%;
  border: 0.1rem solid transparent;
  border-radius: var(--border-radius-interactable);
  background-color: var(--bgc-inputfield-on-body);
  padding: var(--layout-spacing-unit) !important;
  box-sizing: border-box;
  margin-bottom: 0;
}
.portal-search__input:focus {
  border-color: var(--color-focus);
  outline: none;
}
.portal-search__wrapper {
  background-color: rgba(0,0,0,0);
}
/*# sourceMappingURL=src/components/search/PortalSearch.css.map */