.portal-sidenavigation {
  height: calc(100vh - (var(--portal-header-height) + 0.5rem));
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 748px) {
  .portal-sidenavigation {
    overflow-y: auto;
  }
}
.portal-sidenavigation__link {
  padding: 1em 0 1em 20px;
  cursor: pointer;
  height: auto;
  width: 100%;
  justify-content: left;
  font-size: var(--font-size-4);
  color: var(--font-color-contrast-high);
  font-weight: 600;
  text-transform: uppercase;
  background-color: rgba(0,0,0,0);
  border: 0.2rem solid rgba(0,0,0,0);
}
.portal-sidenavigation__link:focus {
  border: 0.2rem solid var(--color-focus);
  outline: 0;
}
.portal-sidenavigation__user-row {
  padding-left: var(--layout-spacing-unit);
  display: flex;
  height: 6rem;
  font-weight: var(--font-weight-bold);
}
.portal-sidenavigation__user-row svg {
  fill: currentColor;
  height: 4rem;
  width: 4rem;
  margin: 1rem;
  border-radius: var(--border-radius-circles);
}
.portal-sidenavigation__user-row> div {
  margin: auto 0;
  padding-left: var(--layout-spacing-unit);
}
.portal-sidenavigation--username {
  padding-left: 3px;
}
.portal-sidenavigation__logout-link {
  cursor: pointer;
  background-color: rgba(0,0,0,0);
  color: var(--font-color-contrast-high);
  font-size: var(--font-size-4);
  border: 0.2rem solid rgba(0,0,0,0);
}
.portal-sidenavigation__logout-link:focus {
  border: 0.2rem solid var(--color-focus);
  outline: 0;
}
.portal-sidenavigation__logout-link span {
  text-decoration: underline;
}
.portal-sidenavigation__login-header {
  border-bottom: 4px solid var(--bgc-content-body);
}
.portal-sidenavigation__menu {
  margin: 0;
  margin-bottom: auto;
  padding-left: 0;
}
.portal-sidenavigation__menu-item {
  margin-left: 0;
}
.portal-sidenavigation__menu-item--show {
  display: block;
}
.portal-sidenavigation__menu-item--hide {
  display: none;
}
.portal-sidenavigation__menu-subItem {
  margin-left: 0;
}
.portal-sidenavigation__menu-subItem--parent {
  text-transform: uppercase;
  padding-left: 4rem;
}
.portal-sidenavigation__edit-mode {
  border: none;
  border-radius: unset;
  border-top: 0.2rem solid var(--bgc-content-body);
}
.portal-sidenavigation__edit-mode span {
  margin: 0.2rem;
}
.portal-sidenavigation__edit-mode:focus span {
  margin: 0;
}
.portal-sidenavigation__fade-left-right,
.portal-sidenavigation__fade-right-left {
  animation-duration: 0.4s;
}
.portal-sidenavigation__fade-right-left {
  animation-name: fadeOutRight;
}
.portal-sidenavigation__fade-left-right {
  animation-name: fadeInLeft;
}
@-moz-keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(20rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(20rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@-o-keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(20rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(20rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@-moz-keyframes fadeOutRight {
  0% {
    opacity: 0;
    transform: translateX(20rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 0;
    transform: translateX(20rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@-o-keyframes fadeOutRight {
  0% {
    opacity: 0;
    transform: translateX(20rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadeOutRight {
  0% {
    opacity: 0;
    transform: translateX(20rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
/*# sourceMappingURL=src/components/navigation/SideNavigation.css.map */