
.choose-tab
  margin-top: calc(4 * var(--layout-spacing-unit))
  display: flex
  align-items: center

  &:first-of-type
    margin-top: 0

  &__button
    display: flex
    background-color: var(--button-bgc)
    align-items: center
    cursor: pointer
    border: 2px solid transparent
    border-radius: var(--border-radius-container)
    padding: var(--layout-spacing-unit)
    width: 100%

    &:focus, &:active, &:hover
      border-color: var(--color-focus)

    img
      height: var(--button-size)
      margin-right: var(--layout-spacing-unit)

  .icon-button
    margin-left: var(--layout-spacing-unit)
